import React from 'react'
import { Datagrid, DateField, Filter, ReferenceField, SelectInput, TextField } from 'react-admin'
import List from '../components/List'
import ReferenceInput from '../components/ReferenceInput'
import { BusinessCenter } from '@material-ui/icons'

export const LoyaltyAccountsIcon = BusinessCenter

const LoyaltyAccountsFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="companyId" reference="companies" alwaysOn allowEmpty>
            <SelectInput source="name"/>
        </ReferenceInput>
        <ReferenceInput source="userId" reference="users" alwaysOn allowEmpty>
            <SelectInput source="id"/>
        </ReferenceInput>
    </Filter>
)

export const LoyaltyAccountsList = (props) => (
    <List {...props} sort={{ field: 'companyId', order: 'DESC' }} filters={<LoyaltyAccountsFilter/>}>
        <Datagrid>
            <TextField source="id"/>
            <ReferenceField source="companyId" reference="companies" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="userId" reference="users" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <TextField source="amount"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
        </Datagrid>
    </List>
)
