import React from 'react'
import { Admin, fetchUtils, Resource } from 'react-admin'
import { createBrowserHistory as createHistory } from 'history'
import simpleRestProvider from './ra-data-advanced-rest'
import authProvider from './authProvider'
import { i18nProvider } from './i18n'
import MyLayout from './MyLayout'
import MyLoginPage from './components/MyLoginPage'
import Dashboard from './Dashboard'
import customRoutes from './customRoutes'
import { RestaurantCreate, RestaurantEdit, RestaurantIcon, RestaurantList, RestaurantShow } from './resources/Restaurants'
import { CompanyCreate, CompanyEdit, CompanyIcon, CompanyList, CompanyShow } from './resources/Companies'
import { TransactionsIcon, TransactionsList, TransactionsShow } from './resources/Transactions'
import { WirecardRequestsIcon, WirecardRequestsList, WirecardRequestsShow } from './resources/WirecardRequests'
import { ClientsIcon, ClientsList } from './resources/Clients'
import { ConfigurationsCreate, ConfigurationsEdit, ConfigurationsIcon, ConfigurationsList } from './resources/Configurations'
import {
    WirecardMerchantConfigsCreate,
    WirecardMerchantConfigsEdit,
    WirecardMerchantConfigsIcon,
    WirecardMerchantConfigsList,
    WirecardMerchantConfigsShow,
} from './resources/WirecardMerchantConfigs'
import { WebHooksCreate, WebHooksEdit, WebHooksIcon, WebHooksList, WebHooksShow } from './resources/WebHooks'
import { PrinterReportsCreate } from './resources/PrinterReports'
import { PrintersCreate, PrintersEdit, PrintersIcon, PrintersList } from './resources/Printers'
import { PaymentGatewayTransactionIcon, PaymentGatewayTransactionList, PaymentGatewayTransactionShow } from './resources/PaymentGatewayTransactions'
// import { RevenuesIcon, RevenuesList } from './resources/Revenues'
import {
    WirecardInstantPaymentNotificationsIcon,
    WirecardInstantPaymentNotificationsList,
    WirecardInstantPaymentNotificationsShow,
} from './resources/WirecardInstantPaymentNotifications'
import { WirecardCreditCardInfosIcon, WirecardCreditCardInfosList, WirecardCreditCardInfosShow } from './resources/WirecardCreditCardInfos'
import { PrinterConfigurationsCreate, PrinterConfigurationsEdit, PrinterConfigurationsIcon, PrinterConfigurationsList } from './resources/PrinterConfigurations'
import { PackagesCreate, PackagesEdit, PackagesIcon, PackagesList } from './resources/Packages'
import { PackageFeesCreate, PackageFeesEdit, PackageFeesIcon, PackageFeesList, PackageFeesShow } from './resources/PackageFees'
import { WirecardPaymentMethodsEdit, WirecardPaymentMethodsIcon, WirecardPaymentMethodsList, WirecardPaymentMethodsShow } from './resources/WirecardPaymentMethods'
import { WirecardRefundsIcon, WirecardRefundsList, WirecardRefundsShow } from './resources/WirecardRefunds'
import { VoucherRequestsIcon, VoucherRequestsList, VoucherRequestsShow } from './resources/VoucherRequests'
import { VoucherCardsCreate, VoucherCardsEdit, VoucherCardsIcon, VoucherCardsList } from './resources/VoucherCards'
import { VoucherBalancesIcon, VoucherBalancesList } from './resources/VoucherBalances'
import { StripeAccountsIcon, StripeAccountsList, StripeAccountsShow } from './resources/StripeAccounts'
import { StripeBusinessConfigsCreate, StripeBusinessConfigsEdit, StripeBusinessConfigsIcon, StripeBusinessConfigsList } from './resources/StripeBusinessConfigs'
import { LoyaltyConfigurationsCreate, LoyaltyConfigurationsEdit, LoyaltyConfigurationsIcon, LoyaltyConfigurationsList } from './resources/LoyaltyConfigurations'
import { UsersEdit, UsersIcon, UsersList } from './resources/Users'
import { LoyaltyAccountsIcon, LoyaltyAccountsList } from './resources/LoyaltyAccounts'
import { LoyaltyEventsIcon, LoyaltyEventsList } from './resources/LoyaltyEvents'

const history = createHistory()

function App () {
    const httpClient = async (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json' })
        }

        options.headers.set('Key-Inflection', 'camel')

        if (options.resource && options.resource === 'printer_reports') {
            return fetchUtils.fetchJson(url, options)
        }

        const token = localStorage.getItem('token')

        options.user = {
            authenticated: true,
            token: `Bearer token=${token}`,
        }
        return fetchUtils.fetchJson(url, options)
    }

    return (
        <Admin history={history}
               layout={MyLayout}
               customRoutes={customRoutes}
               authProvider={authProvider}
               loginPage={MyLoginPage}
               dataProvider={simpleRestProvider(`${process.env.REACT_APP_BASE_URL}/admin/v1`, httpClient)}
               dashboard={Dashboard}
               i18nProvider={i18nProvider}
        >
            <Resource name={'companies'} list={CompanyList} edit={CompanyEdit} create={CompanyCreate} icon={CompanyIcon} show={CompanyShow}/>
            <Resource name={'restaurants'} list={RestaurantList} edit={RestaurantEdit} create={RestaurantCreate} icon={RestaurantIcon} show={RestaurantShow}/>
            <Resource name={'transactions'} list={TransactionsList} icon={TransactionsIcon} show={TransactionsShow}/>
            <Resource name={'payment_gateway_transactions'} list={PaymentGatewayTransactionList} icon={PaymentGatewayTransactionIcon} show={PaymentGatewayTransactionShow}/>
            <Resource name={'wirecard_refunds'} list={WirecardRefundsList} icon={WirecardRefundsIcon} show={WirecardRefundsShow}/>
            <Resource name={'clients'} list={ClientsList} icon={ClientsIcon}/>
            <Resource name={'configurations'} list={ConfigurationsList} icon={ConfigurationsIcon} create={ConfigurationsCreate} edit={ConfigurationsEdit}/>
            <Resource name={'printers'} list={PrintersList} icon={PrintersIcon} create={PrintersCreate} edit={PrintersEdit}/>
            <Resource name={'printer_configurations'} list={PrinterConfigurationsList} icon={PrinterConfigurationsIcon} edit={PrinterConfigurationsEdit}
                      create={PrinterConfigurationsCreate}/>
            <Resource name={'web_hooks'} list={WebHooksList} icon={WebHooksIcon} create={WebHooksCreate} edit={WebHooksEdit} show={WebHooksShow}/>
            <Resource name={'packages'} list={PackagesList} icon={PackagesIcon} create={PackagesCreate} edit={PackagesEdit}/>
            <Resource name={'package_fees'} list={PackageFeesList} icon={PackageFeesIcon} create={PackageFeesCreate} edit={PackageFeesEdit} show={PackageFeesShow}/>
            {/*<Resource name={'transactions.revenue'} list={RevenuesList} icon={RevenuesIcon}/>*/}
            <Resource name={'printer_reports'} create={PrinterReportsCreate}/>
            <Resource name={'wirecard_requests'} list={WirecardRequestsList} icon={WirecardRequestsIcon} show={WirecardRequestsShow}/>
            <Resource name={'wirecard_instant_payment_notifications'} list={WirecardInstantPaymentNotificationsList} show={WirecardInstantPaymentNotificationsShow}
                      icon={WirecardInstantPaymentNotificationsIcon}/>
            <Resource name={'wirecard_credit_card_infos'} list={WirecardCreditCardInfosList} show={WirecardCreditCardInfosShow} icon={WirecardCreditCardInfosIcon}/>
            <Resource name={'wirecard_merchant_configs'} list={WirecardMerchantConfigsList} icon={WirecardMerchantConfigsIcon} create={WirecardMerchantConfigsCreate}
                      edit={WirecardMerchantConfigsEdit}
                      show={WirecardMerchantConfigsShow}/>
            <Resource name={'wirecard_payment_methods'} list={WirecardPaymentMethodsList} edit={WirecardPaymentMethodsEdit} icon={WirecardPaymentMethodsIcon}
                      show={WirecardPaymentMethodsShow}/>
            <Resource name={'voucher_requests'} list={VoucherRequestsList} icon={VoucherRequestsIcon} show={VoucherRequestsShow}/>
            <Resource name={'voucher_cards'} list={VoucherCardsList} icon={VoucherCardsIcon} create={VoucherCardsCreate} edit={VoucherCardsEdit}/>
            <Resource name={'voucher_balances'} list={VoucherBalancesList} icon={VoucherBalancesIcon}/>
            <Resource name={'stripe_accounts'} list={StripeAccountsList} icon={StripeAccountsIcon} show={StripeAccountsShow}/>
            <Resource name={'stripe_business_configs'} list={StripeBusinessConfigsList} icon={StripeBusinessConfigsIcon} edit={StripeBusinessConfigsEdit}
                      create={StripeBusinessConfigsCreate}/>
            <Resource name={'users'} list={UsersList} icon={UsersIcon} edit={UsersEdit}/>
            <Resource name={'loyalty_configurations'} list={LoyaltyConfigurationsList} icon={LoyaltyConfigurationsIcon} edit={LoyaltyConfigurationsEdit}
                      create={LoyaltyConfigurationsCreate}/>
            <Resource name={'loyalty_accounts'} list={LoyaltyAccountsList} icon={LoyaltyAccountsIcon}/>
            <Resource name={'loyalty_events'} list={LoyaltyEventsList} icon={LoyaltyEventsIcon}/>
        </Admin>
    )
}

export default App
