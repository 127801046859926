import React from 'react'
import { Datagrid, DateField, DeleteButton, Edit, required, SimpleForm, TextField, TextInput } from 'react-admin'
import List from '../components/List'
import { SupervisorAccount } from '@material-ui/icons'

export const UsersIcon = SupervisorAccount

export const UsersList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="authProviderId"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
            <DeleteButton/>
        </Datagrid>
    </List>
)

const UsersTitle = ({ record }) => {
    return <span>User {record ? `"${record.name}"` : ''}</span>
}

export const UsersEdit = (props) => (
    <Edit title={<UsersTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="authProviderId" validate={[required()]}/>
        </SimpleForm>
    </Edit>
)
