export default {
    login: (params) => {
        const token = params.token
        console.log('logged in')
        if (token) {
            localStorage.setItem('token', params.token)
            return Promise.resolve()
        } else {
            localStorage.removeItem('token')
            return Promise.reject()
        }
    },
    checkAuth: () => {
        console.log('CHECK')

        return Promise.resolve()
    },
    logout: (params) => {
        console.log('LOGOUT')
        localStorage.removeItem('token')
        return Promise.resolve('/logout')
    },
    checkError: (error) => {
        const status = error.status
        console.log(`ERROR: ${status}`)
        if (status === 401 || status === 403) {
            localStorage.removeItem('token')
            return Promise.reject()
        }
        return Promise.resolve()
    },
    getPermissions: (params) => {
        // TODO allows us to use roles https://marmelab.com/react-admin/Authorization.html
        console.log('AUTH_GET_PERMISSIONS')

        return Promise.resolve('admin')

        // if (localStorage.getItem('isAuthenticated') === '1') {
        //     return Promise.resolve('admin')
        // } else {
        //     return Promise.reject()
        // }
    },
}
