import React from 'react'
import {
    Create,
    Datagrid,
    DateField,
    DeleteButton,
    Edit,
    EditButton,
    Filter,
    ReferenceField,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin'
import List from '../components/List'
import ReferenceInput from '../components/ReferenceInput'
import { BusinessCenter } from '@material-ui/icons'

export const StripeBusinessConfigsIcon = BusinessCenter

const StripeBusinessConfigsFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="restaurantId" reference="restaurants" alwaysOn allowEmpty>
            <SelectInput source="restaurant"/>
        </ReferenceInput>
    </Filter>
)

export const StripeBusinessConfigsList = (props) => (
    <List {...props} sort={{ field: 'restaurantId', order: 'DESC' }} filters={<StripeBusinessConfigsFilter/>}>
        <Datagrid>
            <TextField source="id"/>
            <ReferenceField source="restaurantId" reference="restaurants" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="applicationFeeAmount"/>
            <ReferenceField source="stripeAccountId" reference="stripe_accounts" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <DateField source="createdAt" showTime/>
            <ShowButton/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
)

const StripeBusinessConfigsTitle = ({ record }) => {
    return <span>StripeBusinessConfig {record ? `"${record.id}"` : ''}</span>
}

export const StripeBusinessConfigsEdit = (props) => (
    <Edit title={<StripeBusinessConfigsTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <ReferenceInput source="restaurantId" reference="restaurants" validate={[required()]}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="applicationFeeAmount" validate={[required()]}/>
            <ReferenceInput source="stripeAccountId" reference="stripe_accounts" validate={[required()]}>
                <SelectInput optionText="id"/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
)

export const StripeBusinessConfigsCreate = (props) => (
    <Create title="Create a StripeBusinessConfig" {...props}>
        <SimpleForm>
            <ReferenceInput source="restaurantId" reference="restaurants" validate={[required()]}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="applicationFeeAmount" validate={[required()]}/>
            <ReferenceInput source="stripeAccountId" reference="stripe_accounts" validate={[required()]}>
                <SelectInput optionText={(record) => `${record.id} - ${record.email}`}/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
)
