import React from 'react'
import { Create, Datagrid, DateField, Edit, EditButton, Filter, NumberInput, ReferenceField, required, SelectInput, SimpleForm, TextField, TextInput } from 'react-admin'
import List from '../components/List'
import ReferenceInput from '../components/ReferenceInput'
import { BusinessCenter } from '@material-ui/icons'

export const LoyaltyConfigurationsIcon = BusinessCenter

const LoyaltyConfigurationsFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="companyId" reference="companies" alwaysOn allowEmpty>
            <SelectInput source="company"/>
        </ReferenceInput>
    </Filter>
)

export const LoyaltyConfigurationsList = (props) => (
    <List {...props} sort={{ field: 'companyId', order: 'DESC' }} filters={<LoyaltyConfigurationsFilter/>}>
        <Datagrid>
            <TextField source="id"/>
            <ReferenceField source="companyId" reference="companies" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="threshold"/>
            <TextField source="pointValue"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
            <EditButton/>
        </Datagrid>
    </List>
)

const LoyaltyConfigurationsTitle = ({ record }) => {
    return <span>LoyaltyConfiguration {record ? `"${record.name}"` : ''}</span>
}

export const LoyaltyConfigurationsEdit = (props) => (
    <Edit title={<LoyaltyConfigurationsTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <ReferenceInput source="companyId" reference="companies" validate={[required()]}>
                <SelectInput source="name"/>
            </ReferenceInput>
            <NumberInput source="threshold" validate={[required()]}/>
            <NumberInput source="pointValue" validate={[required()]}/>
        </SimpleForm>
    </Edit>
)

export const LoyaltyConfigurationsCreate = (props) => (
    <Create title="Create a LoyaltyConfiguration" {...props}>
        <SimpleForm>
            <ReferenceInput source="companyId" reference="companies" validate={[required()]}>
                <SelectInput source="name"/>
            </ReferenceInput>
            <NumberInput source="threshold" validate={[required()]}/>
            <NumberInput source="pointValue" validate={[required()]}/>
        </SimpleForm>
    </Create>
)
