import React from 'react'
import { Create, Datagrid, DeleteButton, Edit, EditButton, ReferenceField, required, SelectInput, SimpleForm, TextField, TextInput } from 'react-admin'
import List from '../components/List'
import ReferenceInput from '../components/ReferenceInput'
import { Settings } from '@material-ui/icons'

export const ConfigurationsIcon = Settings

export const ConfigurationsList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id"/>
            <ReferenceField source="restaurantId" reference="restaurants" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="type"/>
            <TextField source="configType"/>
            <TextField source="value"/>
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
)

const ConfigurationsTitle = ({ record }) => {
    return <span>Configurations {record ? `"${record.name}"` : ''}</span>
}

export const ConfigurationsEdit = (props) => (
    <Edit title={<ConfigurationsTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <ReferenceInput source="restaurantId" reference="restaurants" validate={[required()]}>
                <SelectInput source="restaurant"/>
            </ReferenceInput>
            <SelectInput source="type" choices={[
                { id: 'Configuration::Global', name: 'Global' },
                { id: 'Configuration::Restaurant', name: 'Restaurant' },
            ]} validate={[required()]}/>
            <SelectInput source="configType" choices={[
                { id: 'togo', name: 'togo' },
                { id: 'skip_tips', name: 'skip_tips' },
                { id: 'skip_camera', name: 'skip_camera' },
                { id: 'enable_voucher', name: 'enable_voucher' },
            ]} validate={[required()]}/>
            <TextInput source="value" validate={[required()]}/>
        </SimpleForm>
    </Edit>
)

export const ConfigurationsCreate = (props) => (
    <Create title="Create a Configuration" {...props}>
        <SimpleForm>
            <ReferenceInput source="restaurantId" reference="restaurants" validate={[required()]}>
                <SelectInput source="restaurant"/>
            </ReferenceInput>
            <SelectInput source="type" choices={[
                { id: 'Configuration::Global', name: 'Global' },
                { id: 'Configuration::Restaurant', name: 'Restaurant' },
            ]} validate={[required()]}/>
            <SelectInput source="configType" choices={[
                { id: 'togo', name: 'togo' },
                { id: 'skip_tips', name: 'skip_tips' },
                { id: 'skip_camera', name: 'skip_camera' },
                { id: 'enable_voucher', name: 'enable_voucher' },
            ]} validate={[required()]}/>
            <TextInput source="value" validate={[required()]}/>
        </SimpleForm>
    </Create>
)
