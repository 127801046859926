import React from 'react'
import { Create, Datagrid, DateField, Edit, EditButton, ReferenceField, required, SelectInput, SimpleForm, TextField, TextInput } from 'react-admin'
import List from '../components/List'
import { CardGiftcard } from '@material-ui/icons'
import ReferenceInput from '../components/ReferenceInput'

export const VoucherCardsIcon = CardGiftcard

export const VoucherCardsList = (props) => (
    <List {...props} sort={{ field: 'id', order: 'ASC' }}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="qrCode"/>
            <TextField source="numberCode"/>
            <TextField source="pin"/>
            <ReferenceField source="userId" reference="users" link="show">
                <TextField source="authProviderId"/>
            </ReferenceField>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
            <EditButton/>
        </Datagrid>
    </List>
)

const VoucherCardsTitle = ({ record }) => {
    return <span>VoucherCard {record ? `"${record.name}"` : ''}</span>
}

export const VoucherCardsEdit = (props) => (
    <Edit title={<VoucherCardsTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="qrCode" validate={[required()]}/>
            <TextInput source="numberCode" validate={[required()]}/>
            <TextInput source="pin" validate={[required()]}/>
            <ReferenceInput source="userId" reference="users">
                <SelectInput optionText="authProviderId"/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
)

export const VoucherCardsCreate = (props) => (
    <Create title="Create a VoucherCard" {...props}>
        <SimpleForm>
            <TextInput source="qrCode" validate={[required()]}/>
            <TextInput source="numberCode" validate={[required()]}/>
            <TextInput source="pin" validate={[required()]}/>
            <ReferenceInput source="userId" reference="users">
                <SelectInput optionText="authProviderId"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
)
