import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Logout from './components/Logout'
import AuthCallback from './components/AuthCallback'

export default [
    <Redirect from="/restaurants/:id/reports/new" to="/printer_reports/create?restaurantId=:id"/>,
    <Redirect from="/restaurants/:id/printer_reports/new" to="/printer_reports/create?restaurantId=:id"/>,
    <Route exact path='/logout' component={Logout}/>,
    <Route exact path='/auth/callback' component={AuthCallback}/>,
]
