import React from 'react'
import { Datagrid, DateField, ReferenceField, TextField } from 'react-admin'
import List from '../components/List'
import { Money } from '@material-ui/icons'
import CurrencyField from '../field/CurrencyField'

export const VoucherBalancesIcon = Money

export const VoucherBalancesList = (props) => (
    <List {...props} sort={{ field: 'id', order: 'ASC' }}>
        <Datagrid>
            <TextField source="id"/>
            <ReferenceField source="cardId" reference="voucher_cards" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <ReferenceField source="companyId" reference="companies" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <CurrencyField source="amountCents"/>
            <DateField source="amountUpdatedAt" showTime/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
        </Datagrid>
    </List>
)
