import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useLogin, useRedirect } from 'react-admin'
import queryString from 'query-string'

const AuthCallback = (props) => {
    const login = useLogin()
    const redirect = useRedirect()
    const { getAccessTokenSilently } = useAuth0()

    const params = queryString.parse(props.location.search)

    useEffect(() => {
        const fetchToken = async () => {
            const token = await getAccessTokenSilently()
            login({ token: token })
                .catch(() => redirect('/login'))
        }

        console.log(params.code)

        fetchToken()
    }, [])

    return (
        <div></div>
    )
}

export default AuthCallback
