import React from 'react'
import { Datagrid, DateField, Filter, ReferenceField, SelectInput, Show, ShowButton, SimpleShowLayout, TextField } from 'react-admin'
import List from '../components/List'
import { AttachMoney } from '@material-ui/icons'
import JsonTextField from '../field/JsonField'

export const VoucherRequestsIcon = AttachMoney

const VoucherRequestFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="status" allowEmpty choices={[
            { id: 'success', name: 'Success' },
            { id: 'validated', name: 'Validated' },
            { id: 'pending', name: 'Pending' },
            { id: 'canceled', name: 'Canceled' },
            { id: 'failed', name: 'Failed' },
        ]} alwaysOn/>
    </Filter>
)

export const VoucherRequestsList = (props) => (
    <List {...props} filters={<VoucherRequestFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid>
            <TextField source="id"/>
            <ReferenceField source="transactionId" reference="transactions" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <ReferenceField source="cardId" reference="voucher_cards" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <TextField source="status"/>
            <TextField source="pin"/>
            <TextField source="requestType"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
            <ReferenceField source="transactionId" reference="transactions" link="show" label="Restaurant">
                <ReferenceField source="restaurantId" reference="restaurants" link="show">
                    <TextField source="name"/>
                </ReferenceField>
            </ReferenceField>
            <ShowButton/>
        </Datagrid>
    </List>
)

export const VoucherRequestsShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <ReferenceField source="transactionId" reference="transactions" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <ReferenceField source="voucherCardId" reference="voucher_cards" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <TextField source="status"/>
            <TextField source="pin"/>
            <TextField source="requestType"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
            <ReferenceField source="transactionId" reference="transactions" link="show" label="Restaurant">
                <ReferenceField source="restaurantId" reference="restaurants" link="show">
                    <TextField source="name"/>
                </ReferenceField>
            </ReferenceField>
            <JsonTextField source="response"/>
        </SimpleShowLayout>
    </Show>
)
