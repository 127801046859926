import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const Logout = (props) => {
    const { logout } = useAuth0()

    useEffect(() => {
        logout({ returnTo: `${window.location.origin}/login` })
    }, [])

    return null
}

export default Logout
