import React from 'react'
import { Datagrid, Show, ShowButton, SimpleShowLayout, TextField } from 'react-admin'
import List from '../components/List'
import { AttachMoney } from '@material-ui/icons'

export const StripeAccountsIcon = AttachMoney

export const StripeAccountsList = (props) => (
    <List {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="type"/>
            <TextField source="email"/>
            <ShowButton/>
        </Datagrid>
    </List>
)

export const StripeAccountsShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="type"/>
            <TextField source="email"/>
        </SimpleShowLayout>
    </Show>
)
