import React from 'react'
import { Datagrid, DateField, ReferenceField, Show, ShowButton, SimpleShowLayout, TextField } from 'react-admin'
import List from '../components/List'
import { AttachMoney } from '@material-ui/icons'
import JsonTextField from '../field/JsonField'

export const WirecardCreditCardInfosIcon = AttachMoney

export const WirecardCreditCardInfosList = (props) => (
    <List {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid>
            <TextField source="id"/>
            <ReferenceField source="requestId" reference="wirecard_requests" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <TextField source="maskedAccountNumber"/>
            <TextField source="authorizationCode"/>
            <DateField source="createdAt" showTime/>
            <ShowButton/>
        </Datagrid>
    </List>
)

export const WirecardCreditCardInfosShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <ReferenceField source="requestId" reference="wirecard_requests" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <TextField source="maskedAccountNumber"/>
            <TextField source="authorizationCode"/>
            <TextField source="tokenId"/>
            <JsonTextField source="accountHolder"/>
            <JsonTextField source="pspFormResponse"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
        </SimpleShowLayout>
    </Show>
)
