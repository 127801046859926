import React from 'react'
import { Datagrid, DateField, Show, Edit, EditButton, required, SimpleForm, SimpleShowLayout, TextField, TextInput, ShowButton } from 'react-admin'
import List from '../components/List'
import { Settings } from '@material-ui/icons'

export const WirecardPaymentMethodsIcon = Settings

export const WirecardPaymentMethodsList = (props) => (
    <List {...props} sort={{ field: 'id', order: 'ASC' }}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
)

export const WirecardPaymentMethodsShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="apiUrl"/>
            <TextField source="apiUsername"/>
            <TextField source="apiPassword"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
            <EditButton/>
        </SimpleShowLayout>
    </Show>
)

const WirecardPaymentMethodsTitle = ({ record }) => {
    return <span>PackageFee {record ? `"${record.name}"` : ''}</span>
}

export const WirecardPaymentMethodsEdit = (props) => (
    <Edit title={<WirecardPaymentMethodsTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="name" validate={[required()]}/>
        </SimpleForm>
    </Edit>
)
