import React from 'react'
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    Filter,
    ReferenceField,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin'
import List from '../components/List'
import ReferenceInput from '../components/ReferenceInput'
import { BusinessCenter } from '@material-ui/icons'
import DiscardButton from '../components/DiscardButton'

export const WirecardMerchantConfigsIcon = BusinessCenter

const WirecardMerchantConfigsFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="restaurantId" reference="restaurants" alwaysOn allowEmpty>
            <SelectInput source="restaurant"/>
        </ReferenceInput>
    </Filter>
)

export const WirecardMerchantConfigsList = (props) => (
    <List {...props} sort={{ field: 'restaurantId', order: 'DESC' }} filters={<WirecardMerchantConfigsFilter/>}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <ReferenceField source="restaurantId" reference="restaurants" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="merchantAccountId"/>
            <ReferenceField source="paymentMethodId" reference="wirecard_payment_methods" link={false}>
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="createdAt" showTime/>
            <ShowButton/>
            <EditButton/>
            <DiscardButton/>
        </Datagrid>
    </List>
)

const WirecardMerchantConfigsTitle = ({ record }) => {
    return <span>WirecardMerchantConfig {record ? `"${record.name}"` : ''}</span>
}

export const WirecardMerchantConfigsEdit = (props) => (
    <Edit title={<WirecardMerchantConfigsTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="name" validate={[required()]}/>
            <ReferenceInput source="restaurantId" reference="restaurants" validate={[required()]}>
                <SelectInput source="restaurant"/>
            </ReferenceInput>
            <TextInput source="merchantAccountId" validate={[required()]}/>
            <TextInput source="secretKey" validate={[required()]}/>
            <ReferenceInput source="paymentMethodId" reference="wirecard_payment_methods" validate={[required()]}>
                <SelectInput source="paymentMethod"/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
)

export const WirecardMerchantConfigsCreate = (props) => (
    <Create title="Create a WirecardMerchantConfig" {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required()]}/>
            <ReferenceInput source="restaurantId" reference="restaurants" validate={[required()]}>
                <SelectInput source="restaurant"/>
            </ReferenceInput>
            <TextInput source="merchantAccountId" validate={[required()]}/>
            <TextInput source="secretKey" validate={[required()]}/>
            <ReferenceInput source="paymentMethodId" reference="wirecard_payment_methods" validate={[required()]}>
                <SelectInput source="paymentMethod"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
)

export const WirecardMerchantConfigsShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="merchantAccountId"/>
            <ReferenceField source="paymentMethodId" reference="wirecard_payment_methods" link={false}>
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="secretKey"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
            <DateField source="discardedAt" showTime/>
        </SimpleShowLayout>
    </Show>
)
