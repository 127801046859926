import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const MyLoginPage = (props) => {
    const { loading, isAuthenticated, loginWithRedirect } = useAuth0()

    useEffect(() => {
        const redirectLogin = async () => {
            await loginWithRedirect({})
        }

        if (loading || isAuthenticated) {
            return
        }

        redirectLogin()
    }, [])

    return null
}

export default MyLoginPage
