import React from 'react'
import {
    Datagrid,
    DateField,
    Filter,
    FunctionField,
    ImageField,
    ReferenceField,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    TextField,
    useLocale,
    useTranslate,
} from 'react-admin'
import List from '../components/List'
import CurrencyField from '../field/CurrencyField'
import ReferenceInput from '../components/ReferenceInput'
import { AttachMoney } from '@material-ui/icons'
import JsonTextField from '../field/JsonField'


export const TransactionsIcon = AttachMoney

const TransactionFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="restaurantId" reference="restaurants" alwaysOn allowEmpty>
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <SelectInput source="status" allowEmpty choices={[
            { id: 'initialized', name: 'Initialized' },
            { id: 'success', name: 'Success' },
            { id: 'canceled', name: 'Canceled' },
            { id: 'failed', name: 'Failed' },
        ]} alwaysOn/>
    </Filter>
)

export const TransactionsList = (props) => {
    const locale = useLocale()
    const translate = useTranslate()

    return (
        <List {...props} filters={<TransactionFilter/>} sort={{ field: 'createdAt', order: 'DESC' }}>
            <Datagrid>
                <TextField source="id"/>
                <TextField source="status"/>
                <DateField source="createdAt" showTime/>
                <CurrencyField source="totalAmountCents"/>
                <CurrencyField source="parsedAmountCents"/>
                <CurrencyField source="enteredAmountCents"/>
                <FunctionField source="tipsPercentage"
                               render={record => record.tipsPercentage ? (record.tipsPercentage / 100).toLocaleString(locale, { style: 'percent' }) : 'n/a'}/>
                <CurrencyField source="tipsAmountCents"/>
                <CurrencyField source="revenueAmountCents"/>
                <ReferenceField source="restaurantId" reference="restaurants" link="show">
                    <TextField source="name"/>
                </ReferenceField>
                <ShowButton/>
            </Datagrid>
        </List>
    )
}

export const TransactionsShow = (props) => {
    const locale = useLocale()

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id"/>
                <TextField source="status"/>
                <DateField source="createdAt" showTime/>
                <DateField source="updatedAt" showTime/>
                <ReferenceField source="restaurantId" reference="restaurants" link="show">
                    <TextField source="name"/>
                </ReferenceField>
                <CurrencyField source="totalAmountCents"/>
                <CurrencyField source="parsedAmountCents"/>
                <CurrencyField source="enteredAmountCents"/>
                <FunctionField source="tipsPercentage" render={record => (record.tipsPercentage / 100).toLocaleString(locale, { style: 'percent' })}/>
                <CurrencyField source="tipsAmountCents"/>
                <CurrencyField source="voucherAmountCents"/>
                <CurrencyField source="remainingAmountCents"/>
                <CurrencyField source="revenueAmountCents"/>
                <JsonTextField source="ocrResponse"/>
                <TextField source="waiterNumber"/>
                <TextField source="externalTransactionNumber"/>
                <JsonTextField source="lineItems"/>
                <ReferenceField source="clientId" reference="clients" link={false}>
                    <TextField source="email"/>
                </ReferenceField>
                <TextField source="clientReceiptSendingStatus"/>
                <ImageField source="receiptPhotoUrl"/>
            </SimpleShowLayout>
        </Show>
    )
}
