import React from 'react'
import { Datagrid, DateField, ReferenceField, TextField } from 'react-admin'
import List from '../components/List'
import { BusinessCenter } from '@material-ui/icons'

export const LoyaltyEventsIcon = BusinessCenter

export const LoyaltyEventsList = (props) => (
    <List {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid>
            <TextField source="id"/>
            <ReferenceField source="transactionId" reference="transactions" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <ReferenceField source="accountId" reference="loyalty_accounts" link="show">
                <TextField source="id"/>
            </ReferenceField>
            <TextField source="amount"/>
            <TextField source="requestType"/>
            <DateField source="createdAt" showTime/>
            <DateField source="updatedAt" showTime/>
        </Datagrid>
    </List>
)
